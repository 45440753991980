<template>
  <div class="container-fluid mx-auto">
    <h1 v-html="$t('userList')" class="row d-flex justify-content-center"></h1>
    <button class="btn btn-primary btn-sm float-right add-user-button"
            @click="openAddUserModal()"
            v-html="$t('addUser')"></button>
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th v-html="$t('id')"></th>
          <th v-html="$t('email')"></th>
          <th v-html="$t('action')"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.user.id }}</td>
          <td>{{ user.user.email }}</td>
          <td>
            <div class="button-container">
              <button class="btn btn-danger btn-block btn-sm mr-1 button-small-height icon-button"
                      @click="setPaid(user)"><i v-if="user.user.paymentStatus===0" class="fas fa-credit-card"></i><i
                  v-else class="fas fa-file-invoice"></i></button>
              <button class="btn btn-danger btn-block btn-sm mr-1 button-small-height icon-button"
                      @click="deleteUser(user)"><i class="fas fa-trash"></i></button>
              <button class="btn btn-danger btn-block btn-sm button-small-height icon-button"
                      @click="openLogModal(user)"><i class="fas fa-file-lines"></i></button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <modal v-if="showAddUser" @close="closeAddUserModal">
      <template #header>
        <h2 v-html="$t('addUser')"></h2>
      </template>

      <template #body>
        <form v-on:submit.prevent="addUser">
          <div class="form-group">
            <label for="email" v-html="$t('email')"></label>
            <input class="form-control" type="email" id="email" v-model="userForm.email">
          </div>

          <div class="form-group">
            <label for="language" v-html="$t('language')"></label>
            <select id="language" v-model="userForm.selectedLanguage">
              <option>de</option>
              <option>en</option>
            </select>
          </div>
        </form>
      </template>

      <template #footer>
        <div class="row">
          <div class="col-md-6">
            <button class="btn btn-outline-secondary btn-block" @click="closeAddUserModal()"
                    v-html="$t('close')"></button>
          </div>
          <div class="col-md-6">
            <button class="btn btn-primary btn-block" @click="addUser()" v-html="$t('save')"></button>
          </div>
        </div>
      </template>
    </modal>

    <modal v-if="showLog" @close="closeLogModal">
      <template #header>
        <h2 v-html="$t('logs')"></h2>
      </template>

      <template #body>
        <div>
          <table class="table">
            <thead>
            <tr>
              <th v-html="$t('action')"></th>
              <th v-html="$t('date')"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="logEntry in selectedUser.userLogs" :key="logEntry.action">
              <td>{{ logEntry.action }}</td>
              <td>{{ formatTimestamp(logEntry.createdTimestamp) }}</td>
            </tr>
            </tbody>
          </table>
        </div>

      </template>

      <template #footer>
        <button class="btn btn-outline-secondary btn-block" @click="closeLogModal" v-html="$t('close')"></button>
      </template>
    </modal>

    <notification-box
        :message="notificationMessage"
        :is-success="isSuccess"
        :is-error="isError"
        :duration="5000"
        :is-visible="isVisible"
    />
  </div>
</template>


<script>
import axios from 'axios';
import Modal from "@/components/ModalBox.vue";
import NotificationBox from "@/components/NotificationBox.vue";
import {mapState} from "vuex";
import moment from 'moment';
import Swal from "sweetalert2";

export default {
  components: {
    NotificationBox,
    Modal
  },
  data() {
    return {
      selectedUser: null,
      users: [],
      showLog: false,
      showAddUser: false,
      userForm: {
        email: "",
        selectedLanguage: "de"
      },
      userFormEmail: {
        id: "",
        email: "",
      },
      notificationMessage: '',
      isSuccess: false,
      isError: false,
      isVisible: false,
    };
  },
  created() {
    let lang = this.$route.query.lang || this.selectedLanguage;
    if ((lang === '' || lang.length === 0 || lang === 'undefined') && (lang !== 'de' || lang !== 'en')) {
      lang = 'de';
    }
    this.$i18n.locale = lang;

    this.fetchUsers();

  },
  methods: {
    fetchUsers() {
      axios.get(process.env.VUE_APP_SERVER_URL + '/api/v1/users')
          .then((response) => {
            this.users = response.data;
          }).catch(error => {
        // Show error notification message
        this.notificationMessage = error.response.data;
        this.isError = true;
        this.isVisible = true; // Set isVisible to true before showing the notification box
        this.showAddUser = false;
      });
    },
    formatTimestamp(timestamp) {
      return moment(timestamp).format('DD.MM.YYYY HH:mm');
    },
    addUser() {
      // Send a POST request to the server to create a new user
      axios.post(process.env.VUE_APP_SERVER_URL + '/api/v1/users', {
        email: this.userForm.email,
        selectedLanguage: this.userForm.selectedLanguage,
      }).then(response => {
        // Show success notification message and reset form
        this.notificationMessage = response.data;
        this.isSuccess = true;
        this.isVisible = true;
        this.fetchUsers();
        this.showAddUser = false;
      }).catch(error => {
        // Show error notification message
        this.notificationMessage = error.response.data;
        this.isError = true;
        this.isVisible = true; // Set isVisible to true before showing the notification box
        this.showAddUser = false;
      });
    },
    setPaid(user) {
      // Display a confirmation dialog before deleting the user
      let infoText = this.$t('paymentStatusInfo');
      Swal.fire({
        title: this.$t('paymentStatus'),
        icon: 'info',
        html: infoText,
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonText: this.$t('oneTimePayment'),
        confirmButtonColor: '#a6bd24',
        denyButtonText: this.$t('aboPayment'),
        denyButtonColor: '#a6bd24',
        cancelButtonText: this.$t('noPayment'),
        cancelButtonColor: '#ff0011',
      }).then((result) => {
        if (result.isConfirmed) {
          this.updatePaymentStatus(user, 1)
        } else if (result.isDenied) {
          this.updatePaymentStatus(user, 2)
        } else {
          this.updatePaymentStatus(user, 0)
        }
      });
    },
    updatePaymentStatus(user, paymentStatus) {
      const data = {
        id: user.user.id,
        paymentStatus: paymentStatus,
      };

      axios.patch(process.env.VUE_APP_SERVER_URL + '/api/v1/users/' + user.user.id, data)
          .then(response => {
            this.notificationMessage = response.data;
            this.isSuccess = true;
            this.isError = false;
            this.fetchUsers();
          })
          .catch(error => {
            this.notificationMessage = error.response.data;
            this.isSuccess = false;
            this.isError = true;
          });
    },
    deleteUser(user) {
      // Display a confirmation dialog before deleting the user
      if (confirm(this.$t('deleteMessageUser'))) {
        // Make an API request to delete the user
        axios.delete(process.env.VUE_APP_SERVER_URL + '/api/v1/users/' + user.user.id)
            .then(response => {
              this.notificationMessage = response.data;
              this.isSuccess = true;
              this.fetchUsers();
            })
            .catch(error => {
              this.notificationMessage = error.response.data;
              this.isError = true;
            });
      }
    },

    openLogModal(user) {
      this.selectedUser = user;
      this.showLog = true;
    },
    closeLogModal() {
      this.showLog = false;
      this.selectedUser = null;
    },

    closeAddUserModal() {
      this.showAddUser = false;
      this.userForm.email = "";
      this.userForm.selectedLanguage = "de";
    },
    openAddUserModal() {
      this.showAddUser = true;
    },

  },
  computed: {
    ...
        mapState(['selectedLanguage']),

    // isLoggedIn() {
    //   return sessionStorage.getItem('authToken') !== null;
    // }
  }
};
</script>

<style>

.button-container {
  display: flex;
  flex-wrap: wrap;
}

.button-container button {
  flex: 1;
  align-self: center;
}

.icon-button {
  flex: 0 !important;
}

.button-container button:first-child {
  margin-bottom: 0;
  align-self: flex-start;
  margin-top: 0.5rem;
}

.button-container button:last-child {
  margin-right: 0;
}

.container-fluid {
  max-width: 1200px;
  margin: 0 auto;
}

.add-user-button {
  border-radius: 0 !important;
  margin-bottom: 6px !important;
}

.button-small-height {
  height: 30px !important;
}

.logo-img {
  max-height: 50px;
}
</style>