<template>
  <div class="scroll-container">
    <div class="container-fluid py-3">
      <div class="row">
        <div class="d-flex justify-content-center text-center">
          <img src="../assets/logo.png" alt="Logo" class="logo-img">
        </div>
      </div>
    </div>

    <div class="container-fluid" v-if="customerCheckData && customerCheckData.checkData[0]">
      <div class="row d-flex justify-content-center text-center">
        <h2 v-if="customerCheckData.checkData[0].hasHackTest">
          {{ $t('customerHackTest') }}
          <span>
            {{formatTimestamp(customerCheckData.checkData[0].check.createdTimestamp)}}
          </span>
        </h2>
        <h2 v-else>{{ $t('customerStatusCheck') }}
          <span>
            {{formatTimestamp(customerCheckData.checkData[0].check.createdTimestamp)}}
          </span>
        </h2>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12">
          <div class="flex-item">
            <p class="label">{{ $t('overallScore') }}:</p>
          </div>
          <div class="flex image-container col-lg-12">
            <img v-if="customerCheckData.checkData[0].overallScore"
                 v-bind:src="'data:image/png;base64,'+customerCheckData.checkData[0].overallScore"
                 alt="Overall Score"
                 @click="downloadImage(customerCheckData.checkData[0].overallScore, 'OverallScore.png')"/>
            <img v-else src="../assets/document.png" alt="Overall Score">
          </div>
          <div class="flex-item">
            <p class="label">{{ $t('todoOverview') }}:</p>
          </div>
          <div class="flex image-container col-lg-12">
            <img v-if="customerCheckData.checkData[0].todoOverview"
                 v-bind:src="'data:image/png;base64,'+customerCheckData.checkData[0].todoOverview"
                 alt="ToDo Overview"
                 @click="downloadImage(customerCheckData.checkData[0].todoOverview, 'TodoOverview.png')"/>
            <img v-else src="../assets/document.png" alt="ToDo Overview">
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12">
          <div class="d-flex flex-row" style="margin-bottom: 20px">
            <button class="btn btn-secondary download-button download-button-invert"
                    @click="downloadPDF('statusCheck')"
                    style="margin-right: 10px;">
              {{ $t('downloadStatusCheck') }}
            </button>
            <button v-if="customerCheckData.checkData[0].hasHackTest"
                    class="btn btn-primary download-button"
                    @click="downloadPDF('hackTest')"
                    style="margin-left: 10px;">
              {{ $t('downloadHackTest') }}
            </button>
          </div>
          <div class="image-container">
            <img v-if="customerCheckData.checkData[0].coverImage"
                 v-bind:src="'data:image/png;base64,'+customerCheckData.checkData[0].coverImage"
                 alt="Document"
                 @click="downloadImage(customerCheckData.checkData[0].coverImage,'CoverImage.png')"/>
            <img v-else src="../assets/document.png" alt="Cover Image">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from "moment/moment";
import {mapState} from "vuex";

export default {
  data() {
    return {
      customerCheckData: null,
      errorMessage: '',
      hash:'',
    };
  },
  created() {
    let lang = this.$route.query.lang || this.selectedLanguage;
    if ((lang === '' || lang.length === 0 || lang === 'undefined') && (lang !== 'de' || lang !== 'en')) {
      lang = 'de';
    }
    this.$i18n.locale = lang;

    this.hash = this.$route.params.hashValue;
    const url = process.env.VUE_APP_SERVER_URL + '/api/v1/check/' + this.hash + "?lang=" + lang;

    this.fetchCheck(url);

  },
  methods: {

    fetchCheck(url) {
      axios.get(url)
          .then((response) => {
            this.customerCheckData = response.data;
            console.log(this.customerCheckData);
            this.hasPayed = this.customerCheckData.customer.paymentStatus > 0;
          })
          .catch((error) => {
            Swal.fire({
              title: this.$t('error'),
              text: error.response.data,
              icon: 'error',
              confirmButtonColor: '#a6bd24'
            })
          });
    },

    formatTimestamp(timestamp) {
      return moment(timestamp).format('DD.MM.YYYY');
    },

    downloadPDF(type) {
      if (!this.hasPayed && type === 'hackTest') {
        let infoText = this.$t('paymentInfo');
        Swal.fire({
          title: this.$t('paymentMissing'),
          icon: 'error',
          html: infoText,
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonColor: '#a6bd24',
          confirmButtonText: this.$t('HackTest'),
          denyButtonText: this.$t('HackTestABO'),
          denyButtonColor: '#a6bd24',
          cancelButtonText: this.$t('cancel'),
        }).then((result) => {
          if (result.isConfirmed) {
            this.stripeCheckout(1)
          } else if (result.isDenied) {
            this.stripeCheckout(2)
          }
        });
      } else {
        axios.get(process.env.VUE_APP_SERVER_URL + '/api/v1/check/download/' + this.customerCheckData.checkData[0].check.hash + '/' + type, {responseType: 'arraybuffer'})
            .then(response => {
              // Create a Blob object from the response data
              const blob = new Blob([response.data], {type: 'application/pdf'})

              // Create a download link and click it to start the download
              const url = window.URL.createObjectURL(blob)
              const link = document.createElement('a')
              link.href = url

              let fileName = "CheckFix_HackTest.pdf"
              console.log(this.customerCheckData.customer);
              const project = this.customerCheckData.customer.domain
              if (type === "statusCheck") {
                fileName = "CheckFix_StatusCheck_" + project + ".pdf"
              } else {
                fileName = "CheckFix_HackTest_" + project + ".pdf"
              }

              link.download = fileName
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            });
      }
    },
    downloadImage(imageData, fileName) {
      const link = document.createElement('a');
      link.href = `data:image/png;base64,${imageData}`;
      link.download = fileName;
      link.click();
    },
    stripeCheckout(productType) {
      // You will be redirected to Stripe's secure checkout page
      const data = {
        email: this.customerEmail,
        hash: this.customerCheckData.customer.hash,
        referrer: window.location.href,
        selectedLanguage: this.selectedLanguage,
        type: productType
      };
      axios.post(process.env.VUE_APP_SERVER_URL + '/api/v1/payment/checkout', data)
          .then((response) => {
            // Show success notification message and reset form
            this.isSuccess = true;
            this.isVisible = true;
            window.location.href = response.data
          })
          .catch((error) => {
            // Show error notification message
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              this.notificationMessage = error.response.data;
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              this.notificationMessage = 'Unknown error occurred';
            } else {
              // Something happened in setting up the request that triggered an Error
              this.notificationMessage = 'Unknown error occurred';
            }
            this.isSuccess = false
            this.isError = true;
            this.isVisible = true; // Set isVisible to true before showing the notification box
          });
    }
  },
  computed: {
    ...mapState(['selectedLanguage','customerEmail']),
  }
};
</script>

<style scoped>
.container-fluid {
  max-width: 1200px;
  margin: 0 auto;
}

.logo-img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.flex-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
  margin-bottom: 20px;
}

.flex-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.1rem;
  box-shadow: 0px 0px 10px rgba(166, 189, 36, 0.3);
  text-align: center;
}

.label {
  margin-top: 0 !important;
}

.flex-item p.label {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: auto;
}

.flex-item p.value {
  font-size: 1rem;
  margin-bottom: auto;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 500vh; /* Adjust the value as needed */
  overflow: auto;
  border-radius: 0.1rem !important;
  box-shadow: 0px 0px 10px rgba(166, 189, 36, 0.3) !important;
}

.image-container img {
  max-width: 100%;
  height: auto;
}

.download-button {
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 0.1rem !important;
  box-shadow: 0px 0px 10px rgba(166, 189, 36, 0.3) !important;
}

.download-button-invert {
  background-color: white;
  color: #a6bd24 !important;
  border-color: #a6bd24 !important;
}

.scroll-container {
  /*max-height: calc(100vh - 150px);*/
  overflow-y: auto;
}

.wrap-text {
  word-break: break-word;
}

.right-container {
  margin-top: -16px !important;
}

@media (max-width: 768px) {
  .right-container {
    margin-top: 0 !important;
  }
}

</style>




