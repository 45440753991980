<template>
  <div class="container-fluid mx-auto">
    <h1 v-html="$t('customerOverview')" class="row d-flex justify-content-center"></h1>

    <h2 v-if="info && info.customer" class="row d-flex justify-content-center">{{ info.customer.domain }}</h2>
    <button class="btn btn-primary btn-sm float-left customer-button"
            @click="performHackTest()"
            v-html="$t('performHackTest')"></button>
    <button class="btn btn-primary btn-sm float-right customer-button"
            @click="openEditDomainsModal()"
            v-html="$t('editDomains')"></button>

    <div class="table-responsive table-responsive-sm table-responsive-md">
      <table class="table">
        <thead>
        <tr>
          <th v-html="$t('id')"></th>
          <th v-html="$t('checkCreated')"></th>
          <th v-html="$t('action')"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="check in info.checks" :key="check.Id">
          <td>{{ check.Id }}</td>
          <td>{{ formatTimestamp(check.createdTimestamp) }}</td>
          <td>
            <div class="button-container">
              <div class="d-flex flex-wrap justify-content-center">
                <button class="btn btn-primary btn-sm btn-block mr-1 button-small-height" @click="showCheck(check)"
                        v-html="$t('showCheck')"></button>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <modal v-if="showEditDomains" @close="closeEditDomainsModal">
      <template #header>
        <h2 v-html="$t('addCustomer')"></h2>
      </template>

      <template #body>
        <form v-on:submit.prevent="submitEditDomains">
          <div class="form-group">
            <label for="mainDomain" v-html="$t('mainDomain')"></label>
            <input class="form-control" type="text" id="mainDomain" v-model="domainForm.mainDomain">
          </div>

          <div class="form-group">
            <label for="language" v-html="$t('language')"></label>
            <select id="language" v-model="domainForm.selectedLanguage">
              <option>de</option>
              <option>en</option>
            </select>
          </div>
        </form>
      </template>

      <template #footer>
        <div class="row">
          <div class="col-md-6">
            <button class="btn btn-outline-secondary btn-block" @click="closeEditDomainsModal()"
                    v-html="$t('close')"></button>
          </div>
          <div class="col-md-6">
            <button class="btn btn-primary btn-block" @click="addDomain()" v-html="$t('save')"></button>
          </div>
        </div>
      </template>
    </modal>

    <notification-box
        :message="notificationMessage"
        :is-success="isSuccess"
        :is-error="isError"
        :duration="5000"
        :is-visible="isVisible"
    />
  </div>
</template>

<script>
import axios from 'axios';
import Modal from "@/components/ModalBox.vue";
import NotificationBox from "@/components/NotificationBox.vue";
import {mapState} from "vuex";
import moment from "moment/moment";
import Swal from "sweetalert2";

export default {
  components: {
    NotificationBox,
    Modal,
  },
  data() {
    return {
      info: [],
      notificationMessage: '',
      domainForm: {
        mainDomain: "",
        additionalDomain1: "",
        additionalDomain2: "",
      },
      isSuccess: false,
      isError: false,
      isVisible: false,
      showEditDomains: false,
    };
  },
  created() {
    let lang = this.$route.query.lang || this.selectedLanguage;
    if ((lang === '' || lang.length === 0 || lang === 'undefined') && (lang !== 'de' || lang !== 'en')) {
      lang = 'de';
    }
    this.$i18n.locale = lang;
    this.fetchCustomerOverview();
  },
  methods: {

    fetchCustomerOverview() {
      const customerHash = this.$route.params.customerHash;
      const url = process.env.VUE_APP_SERVER_URL + '/api/v1/customer/' + customerHash;
      axios.get(url)
          .then((response) => {
            this.info = response.data;
            console.log(this.info);
            this.domainForm.mainDomain = this.info.customer.domain;
            if (this.info.customer.additionalDomains[0]) {
              this.domainForm.additionalDomain1 = this.info.customer.additionalDomains[0];
            }
            if (this.info.customer.additionalDomains[1]) {
              this.domainForm.additionalDomain2 = this.info.customer.additionalDomains[1];
            }
          });
    },
    showCheck(check) {
      // Open the check page in a new tab
      window.open('/check/' + check.hash + "?lang=de", '_blank');
    },
    formatTimestamp(timestamp) {
      return moment(timestamp).format('DD.MM.YYYY HH:mm');
    },
    closeEditDomainsModal() {
      this.showEditDomains = false;
      this.domainForm.mainDomain = "";
      this.domainForm.additionalDomain1 = "";
      this.domainForm.additionalDomain2 = "";
    },
    openEditDomainsModal() {
      this.showEditDomains = true;
    },
    performHackTest(customer) {
      this.isSuccess = false
      this.isError = false;
      this.isVisible = false;
      this.notificationMessage = '';

      Swal.fire({
        title: this.$t('performHackTest'),
        icon: 'info',
        html: this.$t('performHackTestInfoMessage'),
        showCancelButton: true,
        confirmButtonColor: '#a6bd24',
        confirmButtonText: this.$t('perform'),
        cancelButtonText: this.$t('cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.sendPerformHackTest(customer)
        }
      });
    },
    sendPerformHackTest(customer) {
      axios.post(process.env.VUE_APP_SERVER_URL + '/api/v1/performCheckFix', {
        customerId: customer.Id,
        checkFixType: 1,
        selectedLanguage: this.selectedLanguage,
      }).then(response => {
        // Show success notification message and reset form
        this.notificationMessage = response.data;
        this.isSuccess = true;
        this.isError = false;
        this.isVisible = true;
      }).catch(error => {
        // Show error notification message
        this.notificationMessage = error.response.data;
        this.isSuccess = false
        this.isError = true;
        this.isVisible = true; // Set isVisible to true before showing the notification box
      });
    },
  },
  computed: {
    ...
        mapState(['selectedLanguage']),
  }
}
</script>

<style>
.customer-button {
  border-radius: 0 !important;
  margin-bottom: 6px !important;
}

.container-fluid {
  max-width: 1200px;
  margin: 0 auto;
}
</style>