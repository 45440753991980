import { createStore } from 'vuex'

const store = createStore({
    state: {
        selectedLanguage: 'de',
        customerEmail: '',
        username: '',
        notificationMessage: '',
        isSuccess: false,
        isError: false,
        isVisible: false,
    },
    mutations: {
        setSelectedLanguage(state, language) {
            state.selectedLanguage = language
        },
        setCustomerEmail(state, email) {
            state.customerEmail = email
        },
        setUsername(state, username) {
            state.username = username
        },
        showError(state, notificationMessage) {
            state.notificationMessage = notificationMessage;
            state.isError = true;
            state.isVisible = true;
            state.isSuccess = false;
        }
    },
    actions: {
        setSelectedLanguage({ commit }, language) {
            commit('setSelectedLanguage', language);
        },
        setCustomerEmail({ commit }, email) {
            commit('setCustomerEmail', email);
        },
        setUsername({ commit }, username) {
            commit('setUsername', username);
        },
        showError({commit}, notificationMessage) {
            commit('showError', notificationMessage);
        }
    },
    getters: {
        selectedLanguage: state => state.selectedLanguage,
        customerEmail: state => state.customerEmail,
        username: state => state.username,
        notificationMessage: state => state.notificationMessage,
        isError: state => state.isError,
        isVisible: state => state.isVisible,
        isSuccess: state => state.isSuccess,
    }
})

export default store
