<template>
  <div
      class="notification-box"
      :class="{'success': isSuccess, 'error': isError}"
      v-if="message && isVisible"
      v-bind:style="{ animationDuration: duration + 'ms' }"
      @animationend="onAnimationEnd"
  >
    <span>{{ message }}</span>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: '',
    },
    isSuccess: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: Number,
      default: 3000, // Default duration of 3 seconds (3000 ms)
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    showNotification() {
      // Show the notification box
      this.isVisible = true;

      // Hide the notification box after the delay
      setTimeout(() => {
        this.isVisible = false;
      }, this.duration);
    },
    onAnimationEnd() {
      // Hide the notification box after the animation ends
      this.isVisible = false;
    },
  },
  watch: {
    message() {
      this.showNotification();
    },
  },
};
</script>

<style scoped>
.notification-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  color: #fff;
  transition: all 1s ease;
  z-index: 999;
}

.notification-box.success {
  background-color: #a6bd24;
}

.notification-box.error {
  background-color: #dc3545;
}

.notification-box.isVisible {
  opacity: 1;
}
</style>
