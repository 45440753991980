<template>
  <div>
    <div class="container py-5">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <div class="logo-container">
            <div class="col-md-12 text-center">
              <router-link to="/register">
                <img src="../assets/logo.png" alt="Logo" class="logo-img">
              </router-link>
              /
            </div>
          </div>
          <div class="col-md-12 text-center">
            <p v-if="productType===1" class="welcome-text">{{ $t('welcomePageHackTest') }}</p>
            <p v-else-if="productType===2" class="welcome-text">{{ $t('welcomePageHackTestAbo') }}</p>
            <p v-else class="welcome-text">{{ $t('welcomePageStatusCheck') }}</p>
          </div>
          <div class="col-md-12 text-center">
            <p class="welcome-text-bold">{{ $t('emailSent') }}</p>
          </div>
          <div class="col-md-12 text-center">
            <p class="welcome-text">{{ $t('emailReceived') }}</p>
          </div>
          <div v-if="productType>0">
            <div class="col-md-12 text-center">
              <p class="welcome-text">{{ $t('addDomainsText') }}</p>
            </div>
            <form v-on:submit.prevent="submitDomain">
              <div class="flex">
                <div class="input-group form-group">
                  <input v-model="domain" type="text" id="domain-input" :placeholder="$t('addDomains')"
                         @input="addDomain" class="form-control" required>
                </div>
                <div>
                  <button class="btn btn-primary" type="submit" v-text="$t('add')" :disabled="!isDomainValid"></button>
                </div>
              </div>
            </form>

            <div class="table-responsive table-responsive-sm table-responsive-md text-center"
                 :hidden="domains.length === 0">
              <table class="table table-bordered">
                <tr v-for="addedDomain in domains" :key="addedDomain" class="domain-table">
                  <td>{{ addedDomain }}</td>
                  <!--<td><button class="btn btn-danger btn-block btn-sm mr-1 button-small-height icon-button"
                          @click="removeDomain(addedDomain)"><i class="fas fa-trash"></i></button></td>-->
                </tr>
              </table>
            </div>
          </div>
          <div class="col-md-12 text-center">
            <p class="contact">{{ $t('contact') }}<a href="mailto:support@checkfix.io">support@checkfix.io</a></p>
          </div>
          <div class="col-md-12 text-center">
            <img src="../assets/icon.png" alt="icon" class="logo-img">
          </div>

          <notification-box
              :message="notificationMessage"
              :is-success="isSuccess"
              :is-error="isError"
              :duration="5000"
              :is-visible="isVisible"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

html {
  position: relative;
  min-height: 100%;
}

.flex {
  display: flex;
  flex-direction: row;
}

body {
  margin: 0;
  padding-bottom: 80px; /* Height of the footer */
}

.logo-img {
  max-height: 100px;
  max-width: 100%;
}

.contact {
  padding-top: 30px;
  padding-bottom: 20px;
  white-space: pre-line;
  font-size: 20px;
}

.welcome-text {
  padding-top: 10px;
  white-space: pre-line;
  font-size: 20px;
}

.welcome-text-bold {
  white-space: pre-line;
  font-weight: bold;
  font-size: 20px;
}

@media (max-width: 768px) {
  .welcome-text {
    font-size: 14px; /* Adjust the font size as needed */
  }
}


</style>
<script>

import {mapState} from "vuex";
import axios from "axios";
import NotificationBox from "@/components/NotificationBox.vue";

export default {
  components: {
    NotificationBox
  },
  data() {
    return {
      notificationMessage: '',
      productType: 0,
      resetFlag: false,
      domain: '',
      domains: [],
      isSuccess: true,
      isError: false,
      isVisible: true,
    };
  },
  mounted() {
    if (this.$route.query.type === 'default') {
      this.productType = 1;
    } else if (this.$route.query.type === 'abo') {
      this.productType = 2;
    } else {
      this.productType = 0;
    }
  },
  created() {
    let lang = this.$route.query.lang || this.selectedLanguage;
    if ((lang === '' || lang.length === 0 || lang === 'undefined') && (lang !== 'de' || lang !== 'en')) {
      lang = 'de';
    }
    this.$i18n.locale = lang;
  },
  methods: {
    async submitDomain() {
      this.clearNotification()
      if (this.domains.length <= 2) {
        const data = {
          email: this.customerEmail,
          domain: this.domain,
          selectedLanguage: this.selectedLanguage
        };
        axios.post(process.env.VUE_APP_SERVER_URL + '/api/v1/addDomain', data)
            .then((response) => {
              this.domains.push(this.domain)
              this.resetForm();
              this.notificationMessage = response.data;
              this.isSuccess = true;
              this.isError = false;
              this.isVisible = true;
            })
            .catch((error) => {
              // Show error notification message
              this.resetForm()
              this.notificationMessage = error.response.data;
              this.isSuccess = false;
              this.isError = true;
              this.isVisible = true; // Set isVisible to true before showing the notification box
            });
      } else {
        this.notificationMessage = this.$t('domainLimitReached');
        this.isVisible = true;
        this.isError = true;
        this.isSuccess = false;
      }
    },
    clearNotification() {
      this.notificationMessage = '';
      this.isSuccess = false;
      this.isError = false;
      this.emailError = 'OK';
    },
    validateDomainInput() {
      const index = this.domain.indexOf('.');
      return this.domain.length > 0 && !this.domain.includes('@') && ((this.domain.match(/\./g) || []).length === 1) && (this.domain.substring(index)).length > 2;
    },
    resetForm() {
      this.resetFlag = true;
      this.domain = '';
      this.emailError = 'OK';
      this.showEmailError = false;
    },
    addDomain() {
      this.clearNotification();
      this.resetFlag = false;
    },
  },
  computed: {
    isDomainValid() {
      return this.validateDomainInput();
    },
    ...mapState(['selectedLanguage', 'customerEmail'])
  }
};
</script>

