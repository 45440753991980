<template>
  <div>
    <div class="container py-5">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <div class="logo-container">
            <div class="col-md-12 text-center">
              <img src="../assets/logo.png" alt="Logo" class="logo-img">
            </div>
          </div>
          <div class="col-md-12 text-center">
            <p v-if="productType===1" class="register-text">{{ $t('registerPageHackTest') }}</p>
            <p v-else-if="productType===2" class="register-text">{{ $t('registerPageHackTestAbo') }}</p>
            <p v-else class="register-text">{{ $t('registerPageStatusCheck') }}</p>
          </div>

          <div :class="{invisible: !showStep1Icon}" class="text-center custom-icon-div custom-icon-div-top ">
            <i class="bi bi-arrow-down-circle-fill custom-icon opacity-transition"></i>
          </div>

          <form v-on:submit.prevent="submitForm">
            <div class="form-group">
              <input v-model="email" type="email" id="email-input" :placeholder="$t('emailPlaceholder')"
                     class="form-control" required>
              <div :class="{invisible: showStep1Icon}"
                   class="text-center custom-icon-div opacity-transition step2icon custom-icon-div-bottom">
                <i class="bi bi-arrow-down-circle-fill custom-icon"></i>
              </div>
              <div :class="{invisible: !showEmailError}"
                   class="opacity-transition text-danger email-error row mb-4 justify-content-center">{{ emailError }}
              </div>
            </div>


            <div class="form-group">

              <div style="margin-top: 30px;"></div>
              <button class="btn sendButton rounded-circle bg-danger smallerButton" :disabled="!isFormValid"
                      type="submit"></button>
            </div>
          </form>
          <notification-box
              :message="notificationMessage"
              :is-success="isSuccess"
              :is-error="isError"
              :duration="5000"
              :is-visible="isVisible"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>

html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0;
  padding-bottom: 80px; /* Height of the footer */
}

.sendButton {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  /*background-color: red;*/
  color: white;
  font-weight: bold;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  background-image: url("../assets/push_button.png"); /* Add the image as the background */
  background-size: cover;
  background-position: center;
}

@media (max-width: 576px) {
  .smallerButton {
    width: 125px;
    height: 125px;
  }
}

.custom-icon {
  font-size: 30px; /* Adjust the font size as desired to make it bigger */
  color: #a6bd24; /* Change the color to #a6bd24 */
  /* Add more styles as desired */
  padding: 0px !important;
}

.sendButton:active {
  transform: translateX(-50%) scale(0.95); /* Apply the push effect when the button is clicked */
}

.sendButton:hover {
  transform: translateX(-50%) scale(0.95); /* Apply the push effect when the button is clicked */
}

.sendButton .btn-indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transform: scale(0);
  transition: transform 0.3s ease-out;
}

.sendButton.animate .btn-indicator {
  transform: scale(1);
}

.sendButton .btn-text {
  position: relative;
  z-index: 1;
}

.form-group {
  position: relative; /* Enable absolute positioning of the sendButton */
}

.logo-img {
  max-height: 100px;
  max-width: 100%;
}


.register-text {
  font-size: 20px; /* Adjust the font size as needed */
  font-weight: bold;
}

.invisible {
  opacity: 0;
}

.opacity-transition {
  transition: opacity 0.3s ease;
}

.custom-icon-div {
  padding: 0px !important;
}

.step2icon {
  margin-bottom: -30px !important;
}

.custom-icon-div-top {
  margin-bottom: 40px;
}

.custom-icon-div-bottom {
  margin-top: 40px;
}

@media (max-width: 768px) {
  .custom-icon-div-bottom {
    margin-top: 0 !important;
  }

  .custom-icon-div-top {
    margin-top: 0 !important;
  }

  .register-text {
    font-size: 14px; /* Adjust the font size as needed */
  }
}

</style>

<script>

import axios from 'axios';
import {mapActions, mapGetters} from 'vuex';
import NotificationBox from "@/components/NotificationBox.vue";


export default {
  components: {
    NotificationBox,
  },
  data() {
    return {
      //Notification box data
      productType: 0,
      notificationMessage: '',
      isSuccess: false,
      isError: false,
      isVisible: false,
      // Other data properties
      email: '',
      emailError: 'OK',
      showStep1Icon: true,
      showStep2Icon: false,
      showEmailError: false,
    }
  },
  mounted() {
    if (this.$route.query.type === 'default') {
      this.productType = 1;
    } else if (this.$route.query.type === 'abo') {
      this.productType = 2;
    } else {
      this.productType = 0;
    }
  },
  created() {
    let lang = this.$route.query.lang || this.selectedLanguage;
    if ((lang === '' || lang.length === 0 || lang === 'undefined') && (lang !== 'de' || lang !== 'en')) {
      lang = 'de';
    }
    this.$i18n.locale = lang;
  },
  methods: {
    ...mapActions(['setCustomerEmail','setSelectedLanguage']),

    async submitForm() {

      // Clear any existing notification translation
      this.clearNotification();
      this.setCustomerEmail(this.email);
      this.setSelectedLanguage(this.selectedLanguage);
      const data = {
        email: this.email,
        selectedLanguage: this.selectedLanguage
      };

      axios.post(process.env.VUE_APP_SERVER_URL + '/api/v1/submit', data)
          .then((response) => {

            // Show success notification message and reset form
            this.notificationMessage = response.data;
            this.isSuccess = true;
            this.isVisible = true; // Set isVisible to true before showing the notification box
            if (this.productType === 1) {
              this.stripeCheckout()
            } else if (this.productType === 2) {
              this.stripeCheckout()
            } else {
              this.$router.push('/welcome')
            }
          })
          .catch((error) => {
            // Show error notification message
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              this.notificationMessage = error.response.data;
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              this.notificationMessage = 'Unknown error occurred';
            } else {
              // Something happened in setting up the request that triggered an Error
              this.notificationMessage = 'Unknown error occurred';
            }
            this.isSuccess = false
            this.isError = true;
            this.isVisible = true; // Set isVisible to true before showing the notification box
          });
    },

    stripeCheckout() {
      // You will be redirected to Stripe's secure checkout page
      const data = {
        email: this.email,
        referrer: window.location.href,
        selectedLanguage: this.selectedLanguage,
        type: this.productType
      };
      axios.post(process.env.VUE_APP_SERVER_URL + '/api/v1/payment/checkout', data)
          .then((response) => {
            // Show success notification message and reset form
            this.isSuccess = true;
            this.isVisible = true;
            console.log(response.data);
            window.location.href = response.data
          })
          .catch((error) => {
            // Show error notification message
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              this.notificationMessage = error.response.data;
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              this.notificationMessage = 'Unknown error occurred';
            } else {
              // Something happened in setting up the request that triggered an Error
              this.notificationMessage = 'Unknown error occurred';
            }
            this.isSuccess = false
            this.isError = true;
            this.isVisible = true; // Set isVisible to true before showing the notification box
          });
    },

    validateEmail() {

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.email)) {
        this.emailError = this.$t('invalidEmailError');
      } else {
        this.emailError = 'OK';
      }
    },

    validateEmailInput() {

      this.showEmailError = false;
      if (this.email.length > 0) {

        if (this.emailError === 'OK' && this.email !== '') {
          this.showStep1Icon = false;
          this.showStep2Icon = true;
          this.showEmailError = false;
          return true;
        } else {
          this.showStep1Icon = true;
          this.showStep2Icon = false;
          this.showEmailError = true;
          return false;
        }
      } else {
        this.showStep1Icon = true;
        this.showStep2Icon = false;
        this.showEmailError = false;
        return false;
      }
    },

    // Clear notification message and reset state
    clearNotification() {
      this.notificationMessage = '';
      this.isSuccess = false;
      this.isError = false;
      this.showEmailError = false;
      this.emailError = 'OK';
    },
  },

  computed: {
    isFormValid() {
      return this.validateEmailInput();
    },
    ...mapGetters(['customerEmail', 'selectedLanguage']),
  },

  watch: {
    email() {
      this.validateEmail();
    }
  }
}
</script>
