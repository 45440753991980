<template>

  <div>
    <div class="container py-3">
      <div class="row">
        <div class="col-sm-6 col-md-3 col-4 d-flex align-items-center">
          <img v-if="showLoginButton && !!username" src="./assets/logo.png" alt="Logo" class="logo-img">
        </div>

        <div class="col-sm-12 col-md-6 col-4 d-flex justify-content-center">
          <div class="navigation-bar" v-if="showLoginButton && !!username">
            <div class="centered-container">
              <router-link to="/portal"></router-link>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-3 col-4 d-flex justify-content-end">
          <div class="language-switcher">
            <button class="btn btn-block" v-if="!!username">{{username}}</button>
            <div class="login-section">
              <button v-if="showLoginButton && !!username" class="btn btn-primary btn-sm btn-block" @click="logout()">
                Logout
              </button>
            </div>
            <button @click="switchLanguage('en')" :class="{'active': this.selectedLanguage === 'en', 'en': true}"></button>
            <button @click="switchLanguage('de')" :class="{'active': this.selectedLanguage === 'de', 'de': true}"></button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <router-view/>

  <notification-box
      :message="notificationMessage"
      :is-success="isSuccess"
      :is-error="isError"
      :duration="5000"
      :is-visible="isVisible"
  />

  <footer class="footer bg-light">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-12 text-center">
          <a href="https://secinto.com">
            <img src="./assets/secinto-1z-4c-slogan.png" alt="Company Logo" height="40" class="footer-img">
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<style>

.btn-block {
  border-radius: 0;
}

.btn-primary {
  background-color: #a6bd24 !important;
  border-color: #a6bd24 !important;
}

.logo-container {
  height: 150px;
}

.footer-img {
  margin-top: 10px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f8f9fa;
  padding: 2px 0;
  z-index: 1;
}

@media (max-width: 576px) {
  .footer-img {
    margin-top: 0;
  }

  .footer {
    text-align: center;
    height: auto;
  }
}

.language-switcher {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  margin-right: 10px;
  margin-top: 10px;
  top: 0;
  right: 0;
}

.language-switcher button {
  background: none;
  border: none;
  font-size: 18px;
  margin-left: 10px;
  cursor: pointer;
}

.language-switcher button.active {
  font-weight: bold;
}

.language-switcher button.en:before {
  content: 'EN';
}

.language-switcher button.de:before {
  content: 'DE';
}

.centered-container {
  display: flex;
  justify-content: center;
}

.navigation-bar {
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: #ffffff;
  text-align: center;
}

.navigation-bar a {
  display: inline-block;
  margin: 0 10px;
  color: #a6bd24;
  text-decoration: none;
  font-weight: bold;
}

.navigation-bar a:hover {
  color: #ffffff;
  background-color: #a6bd24;
}

</style>

<script>
import axios from "axios";
import router from "@/router";
import NotificationBox from "@/components/NotificationBox.vue";
import {mapState} from "vuex";
import {mapGetters } from "vuex";
import {mapActions } from "vuex";

export default {
  name: 'HomePage',
  components: {NotificationBox},
  data() {
    return {
      selectedLanguage: 'de',
      currentRoute: '',
      show: false,
    }
  },
  created() {
    this.currentRoute = this.$route.path;
    this.$router.afterEach((to) => {
      this.currentRoute = to.path;
    });

    const username = sessionStorage.getItem('username');
    this.setUsername(username);
  },
  methods: {
    ...mapActions(['setUsername','setSelectedLanguage']),
    switchLanguage(language) {
      this.selectedLanguage = language;
      this.setSelectedLanguage(language);
      this.$i18n.locale = language;
    },

    logout() {
      // Clear the auth token from local storage
      sessionStorage.removeItem('authToken');
      sessionStorage.removeItem('username');
      // clear the username in the vuex store
      this.setUsername("");

      // Remove the authorization header from axios
      delete axios.defaults.headers.common['Authorization'];

      // Redirect the user to the login page
      router.push("/login");
    },
  },
  computed: {
    showLoginButton() {
      return this.currentRoute === '/users' ||
          this.currentRoute === '/admin' ||
          this.currentRoute === '/portal' ||
          this.currentRoute === '/checks' ||
          /^\/customers\/checks\/\d+$/.test(this.currentRoute) ||
          /^\/checks\/\d+$/.test(this.currentRoute);
    },

    ...mapState(['selectedLanguage', 'customerEmail']),
    ...mapGetters(['username','notificationMessage', 'isSuccess', 'isError', 'isVisible']),
    },
}
</script>

