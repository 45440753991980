<template>
  <div class="container">
    <div class="col-md-12 text-center">
      <router-link to="/register">
        <img src="../assets/logo.png" alt="Logo" class="logo-img">
      </router-link>/
    </div>

    <div class="row justify-content-center">
      <div class="col-md-6 text-center">
        <h2>Login</h2>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-6">
        <form>
          <div class="form-group">
            <label for="username">Username:</label>
            <input type="text" class="form-control" id="username" v-model="username">
          </div>
          <div class="form-group">
            <label for="apikey">API Key:</label>
            <input type="password" class="form-control" id="apikey" v-model="apikey">
          </div>
        </form>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-6 text-center">
        <button type="button" class="btn btn-primary" @click="login">Login</button>
      </div>
    </div>

    <notification-box
        :message="notificationMessage"
        :is-success="isSuccess"
        :is-error="isError"
        :duration="5000"
        :is-visible="isVisible"
    />
  </div>
</template>


<script>

import router from "@/router";
import axios from "axios";
import {mapActions} from 'vuex';
import { previousRoute } from '@/router';
import NotificationBox from "@/components/NotificationBox.vue";

export default {
  name: 'LoginPage',

  components: {NotificationBox},
  data() {
    return {
      username: '',
      apikey: '',
      notificationMessage: '',
      isSuccess: false,
      isError: false,
      isVisible: false,

    }
  },
  methods: {
    ...mapActions(['setUsername']),
    async login() {
      try {
        await axios.post(process.env.VUE_APP_SERVER_URL + '/api/v1/login', {
          username: this.username,
          apiKey: this.apikey
        }).then(response => {
          // Save the authToken to the local storage
          sessionStorage.setItem('authToken', response.data.apiKey);
          //sessionStorage.setItem('username', this.username);
          // save the username in the vuex store
          this.setUsername(this.username);

          if (previousRoute === null || previousRoute === undefined || previousRoute?.fullPath === null || previousRoute?.fullPath === undefined) {
            router.push('/portal');
            return
          }

          const previousFullPath = previousRoute.fullPath

          if (previousFullPath === '/users' ||
              previousFullPath === '/admin' ||
              previousFullPath === '/portal' ||
              previousFullPath === '/checks' ||
              /^\/customers\/checks\/\d+$/.test(previousFullPath) ||
              /^\/checks\/\d+$/.test(previousFullPath)) {
            router.back();
          } else {
            // Redirect to portal if previous path was null
            router.push('/portal');
          }

        });
      } catch (error) {
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('username');
        delete axios.defaults.headers.common['Authorization'];
        // clear the username in the vuex store
        await this.setUsername("");

        this.username = "";
        this.apikey = "";
        this.notificationMessage = this.$t('invalidUsernameOrPassword')
        this.isError = true;
        this.isVisible = true;
      }
    },
  },
}
</script>

<style scoped>
.container {
  margin-top: 50px;
}

.form-group {
  margin-bottom: 15px;
}

button {
  width: 100%;
}
</style>