<template>
  <div>
    <div class="container py-5">
      <div class="row">
        <div class="col-md-6 offset-md-3">

          <div class="logo-container">
            <div class="col-md-12 text-center">
              <a href="https://check.checkfix.io/register">
                <img src="../assets/logo.png" alt="Logo" class="logo-img">
              </a>
            </div>
          </div>
          <div class="col-md-12 text-center">
            <p class="info-text">{{ $t('forgotDashboardLinkText') }}</p>
          </div>
          <form v-on:submit.prevent="submitCustomerEmail">
            <div class="flex">
              <div class="input-group form-group">
                <input v-model="email" type="text" id="email-input" :placeholder="$t('emailPlaceholder')"
                       class="form-control" required>
              </div>
              <div>
                <button class="btn btn-primary" type="submit" v-text="$t('send')" :disabled="!isFormValid"></button>
              </div>
            </div>
          </form>

          <div class="col-md-12 text-center">
            <p class="contact">{{ $t('contact') }}<a href="mailto:support@checkfix.io">support@checkfix.io</a></p>
          </div>
          <div class="col-md-12 text-center">
            <img src="../assets/icon.png" alt="icon" class="logo-img">
          </div>

          <notification-box
              :message="notificationMessage"
              :is-success="isSuccess"
              :is-error="isError"
              :duration="5000"
              :is-visible="isVisible"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
html {
  position: relative;
  min-height: 100%;
}

.flex {
  display: flex;
  flex-direction: row;
}

body {
  margin: 0;
  padding-bottom: 80px; /* Height of the footer */
}

.logo-img {
  max-height: 100px;
  max-width: 100%;
}

.info-text {
  padding-top: 10px;
  white-space: pre-line;
  font-size: 20px;
}

.info-text-bold {
  white-space: pre-line;
  font-weight: bold;
  font-size: 20px;
}
</style>
<script>
import axios from "axios";
import NotificationBox from "@/components/NotificationBox.vue";
import {mapState} from "vuex";

export default {
  components: {NotificationBox},
  data() {
    return {
      resetFlag: false,
      notificationMessage: '',
      email: '',
      emailError: 'OK',
      showEmailError: false,
      isSuccess: true,
      isError: false,
      isVisible: true,
    };
  },
  created() {
    let lang = this.$route.query.lang || this.selectedLanguage;
    if ((lang === '' || lang.length === 0 || lang === 'undefined') && (lang !== 'de' || lang !== 'en')) {
      lang = 'de';
    }
    this.$i18n.locale = lang;
  },
  methods: {
    async submitCustomerEmail() {
      this.clearNotification()
      const data = {
        email: this.email,
        selectedLanguage: this.selectedLanguage
      };
      axios.post(process.env.VUE_APP_SERVER_URL + '/api/v1/requestDashboardLink', data)
          .then((response) => {
            this.resetForm();
            this.notificationMessage = response.data;
            this.isSuccess = true;
            this.isError = false;
            this.isVisible = true;
          })
          .catch((error) => {
            // Show error notification message
            this.resetForm()
            this.notificationMessage = error.response.data;
            this.isSuccess = false;
            this.isError = true;
            this.isVisible = true; // Set isVisible to true before showing the notification box
          });
    },
    clearNotification() {
      this.notificationMessage = '';
      this.isSuccess = false;
      this.isError = false;
      this.emailError = 'OK';
    },
    validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.email)) {
        this.emailError = this.$t('invalidEmailError');
      } else {
        this.emailError = 'OK';
      }
    },
    validateEmailInput() {
      this.showEmailError = false;
      if (this.email.length > 0) {

        if (this.emailError === 'OK' && this.email !== '') {
          this.showEmailError = false;
          return true;
        } else {
          this.showEmailError = true;
          return false;
        }
      } else {
        this.showEmailError = false;
        return false;
      }
    },
    resetForm() {
      this.resetFlag = true;
      this.domain = '';
      this.emailError = 'OK';
      this.showEmailError = false;
    }
  },
  computed: {
    isFormValid() {
      return this.validateEmailInput();
    },
    ...mapState(['selectedLanguage'])
  },
  watch: {
    email() {
      this.validateEmail();
    }
  }
};
</script>
