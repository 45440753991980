<template>
  <div class="not-found">
    <div class="col-md-12 text-center">
      <router-link to="/register">
        <img src="../assets/logo.png" alt="Logo" class="logo-img">
      </router-link>/
    </div>

    <h1>{{$t('pageNotFoundTitle')}}</h1>
    <p>{{$t('pageNotFoundDescription')}}</p>
    <router-link to="/register">{{ $t('pageNotFoundReturnLink') }}</router-link>
  </div>
</template>

<script setup>
</script>

<style scoped>

.logo-img {
  max-height: 100px;
  max-width: 100%;
  margin-bottom: 20px;
}

.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding: 50px;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

p {
  margin-bottom: 20px;
}

a {
  color: #a6bd24;
  text-decoration: none;
  font-weight: bold;
}
</style>