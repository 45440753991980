<template>
  <div class="container-fluid mx-auto">
    <h1 v-html="$t('customerList')" class="row d-flex justify-content-center"></h1>
    <button class="btn btn-primary btn-sm float-right add-customer-button"
            @click="openAddCustomerModal()"
            v-html="$t('addCustomer')"></button>
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th v-html="$t('email')"></th>
          <th v-html="$t('action')"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="customer in customersData" :key="customer.customer.id">
          <td>{{ customer.customer.email }}</td>
          <td>
            <div class="button-container">
              <button class="btn btn-primary btn-block btn-sm mt-0 mr-1 button-small-height"
                      @click="performStatusCheck(customer.customer)"
                      v-html="$t('performStatusCheck')"></button>
              <button class="btn btn-primary btn-block btn-sm mt-0 mr-1 button-small-height"
                      @click="performHackTest(customer.customer)"
                      v-html="$t('performHackTest')" disabled></button>
              <button class="btn btn-primary btn-block btn-sm mt-0 mr-1 button-small-height"
                      @click="showFiles(customer.customer)"
                      v-html="$t('fileList')"></button>
              <button class="btn btn-danger btn-block btn-sm mt-0 mr-1 button-small-height icon-button"
                      @click="deleteCustomer(customer.customer)"><i class="fas fa-trash"></i></button>
              <button class="btn btn-danger btn-block btn-sm mt-0 button-small-height icon-button"
                      @click="openLogModal(customer.customer)"><i class="fas fa-file-lines"></i></button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <modal v-if="showAddCustomer" @close="closeAddCustomerModal">
      <template #header>
        <h2 v-html="$t('addCustomer')"></h2>
      </template>

      <template #body>
        <form v-on:submit.prevent="submitFormAddCustomer">
          <div class="form-group">
            <label for="email" v-html="$t('email')"></label>
            <input class="form-control" type="email" id="email" v-model="customerForm.email">
          </div>

          <div class="form-group">
            <label for="language" v-html="$t('language')"></label>
            <select id="language" v-model="customerForm.selectedLanguage">
              <option>de</option>
              <option>en</option>
            </select>
          </div>
        </form>
      </template>

      <template #footer>
        <div class="row">
          <div class="col-md-6">
            <button class="btn btn-outline-secondary btn-block" @click="closeAddCustomerModal()"
                    v-html="$t('close')"></button>
          </div>
          <div class="col-md-6">
            <button class="btn btn-primary btn-block" @click="addCustomer()" v-html="$t('save')"></button>
          </div>
        </div>
      </template>
    </modal>

    <modal v-if="showLog" @close="closeLogModal">
      <template #header>
        <h2 v-html="$t('logs')"></h2>
      </template>

      <template #body>
        <div>
          <table class="table">
            <thead>
            <tr>
              <th v-html="$t('action')"></th>
              <th v-html="$t('date')"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="logEntry in selectedCustomer.customerLogs" :key="logEntry.action">
              <td>{{ logEntry.action }}</td>
              <td>{{ formatTimestamp(logEntry.createdTimestamp) }}</td>
            </tr>
            </tbody>
          </table>
        </div>

      </template>

      <template #footer>
        <button class="btn btn-outline-secondary btn-block" @click="closeLogModal" v-html="$t('close')"></button>
      </template>
    </modal>

    <notification-box
        :message="notificationMessage"
        :is-success="isSuccess"
        :is-error="isError"
        :duration="5000"
        :is-visible="isVisible"
    />
  </div>
</template>


<script>
import axios from 'axios';
import Modal from "@/components/ModalBox.vue";
import NotificationBox from "@/components/NotificationBox.vue";
import {mapState} from "vuex";
import moment from 'moment';
import Swal from "sweetalert2";

export default {
  components: {
    NotificationBox,
    Modal
  },
  data() {
    return {
      selectedCustomer: null,
      customersData: [],
      showLog: false,
      showAddCustomer: false,
      customerForm: {
        email: "",
        selectedLanguage: "de"
      },
      notificationMessage: '',
      isSuccess: false,
      isError: false,
      isVisible: false,
    };
  },
  created() {
    let lang = this.$route.query.lang || this.selectedLanguage;
    if ((lang === '' || lang.length === 0 || lang === 'undefined') && (lang !== 'de' || lang !== 'en')) {
      lang = 'de';
    }
    this.$i18n.locale = lang;
    this.fetchCustomers();
  },
  methods: {
    showFiles(customer) {
      // Assuming you have access to the Vue Router instance
      this.$router.push('/customers/checks/' + customer.id);
    },
    fetchCustomers() {
      axios.get(process.env.VUE_APP_SERVER_URL + '/api/v1/customers')
          .then((response) => {
            this.customersData = response.data;
            console.log(this.customersData);
          }).catch(error => {
        // Show error notification message
        this.notificationMessage = error.response.data;
        this.isError = true;
        this.isVisible = true; // Set isVisible to true before showing the notification box
        this.showAddUser = false;
      });
    },
    formatTimestamp(timestamp) {
      return moment(timestamp).format('DD.MM.YYYY HH:mm');
    },
    addCustomer() {
      // Send a POST request to the server to create a new customer
      axios.post(process.env.VUE_APP_SERVER_URL + '/api/v1/customers', {

        email: this.customerForm.email,
        selectedLanguage: this.customerForm.selectedLanguage,

      })
          .then(response => {
            // Show success notification message and reset form
            this.notificationMessage = response.data;
            this.isSuccess = true;
            this.isError = false;
            this.isVisible = true;
            this.fetchCustomers();
            this.showAddCustomer = false;
          })
          .catch(error => {
            // Show error notification message
            this.notificationMessage = error.response.data;
            this.isSuccess = false
            this.isError = true;
            this.isVisible = true; // Set isVisible to true before showing the notification box
            this.showAddCustomer = false;
          });
    },
    deleteCustomer(customer) {
      // Display a confirmation dialog before deleting the customer
      if (confirm(this.$t('deleteMessageCustomer'))) {
        // Make an API request to delete the customer
        axios.delete(process.env.VUE_APP_SERVER_URL + '/api/v1/customers/' + customer.id)
            .then(response => {
              this.notificationMessage = response.data;
              this.isSuccess = true;
              this.fetchCustomers();
            })
            .catch(error => {
              this.notificationMessage = error.response.data;
              this.isError = true;
            });
      }
    },
    performStatusCheck(customer) {
      this.isSuccess = false
      this.isError = false;
      this.isVisible = false;
      this.notificationMessage = '';

      Swal.fire({
        title: this.$t('performStatusCheck'),
        icon: 'info',
        html: this.$t('performStatusCheckMessage'),
        showCancelButton: true,
        confirmButtonColor: '#a6bd24',
        confirmButtonText: this.$t('perform'),
        cancelButtonText: this.$t('cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.sendPerformStatusCheck(customer)
        }
      });
    },
    sendPerformStatusCheck(customer) {
      axios.post(process.env.VUE_APP_SERVER_URL + '/api/v1/performCheckFix', {
        customerId: customer.id,
        checkFixType: 0,
        aelectedLanguage: this.customerForm.selectedLanguage,
      }).then(response => {
        // Show success notification message and reset form
        this.notificationMessage = response.data;
        this.isSuccess = true;
        this.isError = false;
        this.isVisible = true;
      }).catch(error => {
        // Show error notification message
        this.notificationMessage = error.response.data;
        this.isSuccess = false
        this.isError = true;
        this.isVisible = true; // Set isVisible to true before showing the notification box
      });
    },
    performHackTest(customer) {
      this.isSuccess = false
      this.isError = false;
      this.isVisible = false;
      this.notificationMessage = '';

      Swal.fire({
        title: this.$t('performHackTest'),
        icon: 'info',
        html: this.$t('performHackTestInfoMessage'),
        showCancelButton: true,
        confirmButtonColor: '#a6bd24',
        confirmButtonText: this.$t('perform'),
        cancelButtonText: this.$t('cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.sendPerformHackTest(customer)
        }
      });
    },
    sendPerformHackTest(customer) {
      axios.post(process.env.VUE_APP_SERVER_URL + '/api/v1/performCheckFix', {
        customerId: customer.id,
        checkFixType: 1,
        selectedLanguage: this.customerForm.selectedLanguage,
      }).then(response => {
        // Show success notification message and reset form
        this.notificationMessage = response.data;
        this.isSuccess = true;
        this.isError = false;
        this.isVisible = true;
      }).catch(error => {
        // Show error notification message
        this.notificationMessage = error.response.data;
        this.isSuccess = false
        this.isError = true;
        this.isVisible = true; // Set isVisible to true before showing the notification box
      });
    },
    openLogModal(customer) {
      this.selectedCustomer = customer;
      this.showLog = true;
    },
    closeLogModal() {
      this.showLog = false;
      this.selectedCustomer = null;
    },
    closeAddCustomerModal() {
      this.showAddCustomer = false;
      this.customerForm.email = "";
      this.customerForm.selectedLanguage = "de";
    },
    openAddCustomerModal() {
      this.showAddCustomer = true;
    },

  }
  ,
  computed: {
    ...
        mapState(['selectedLanguage']),

    // isLoggedIn() {
    //   return sessionStorage.getItem('authToken') !== null;
    // }
  }
};
</script>

<style>

.button-container {
  display: flex;
  flex-wrap: wrap;
}

.button-container button {
  flex: 1;
  align-self: center;
}

.icon-button {
  flex: 0 !important;
}

.button-container button:first-child {
  margin-bottom: 0;
  align-self: flex-start;
  margin-top: 0.5rem;
}

.button-container button:last-child {
  margin-right: 0;
}

.container-fluid {
  max-width: 1200px;
  margin: 0 auto;
}

.logo-img {
  max-height: 50px;
}

.add-customer-button {
  border-radius: 0 !important;
  margin-bottom: 6px !important;
}

.button-small-height {
  height: 30px !important;
}

.status-check-icon {
  background-image: url('~@/assets/status-check-white.png');
  height: 15px;
  width: 15px;
  display: block;
  /* Other styles here */
}

</style>