// ./locales/de.js
export default {
    hello: 'Hallo',
    goodbye: 'Tschüss',
    emailPlaceholder: 'Geben Sie Ihre Unternehmens E-Mail Adresse ein',
    sendButton: 'Teste mich!',
    invalidEmailError: 'Bitte geben Sie eine gültige E-Mail Adresse ein.',
    company: 'Meine Firma',
    customerOverview : "CheckFix Übersicht",
    allRightsReserved: 'Alle Rechte vorbehalten.',
    error: 'Fehler!',
    success: 'Erfolg!',
    customerHackTest: 'CheckFix Hack-Test',
    customerStatusCheck: 'CheckFix Status-Check',
    email: 'E-Mail',
    overallScore: 'Gesamtbewertung',
    todoOverview: 'Übersicht ToDos pro Kategorie',
    downloadStatusCheck: 'CheckFix Status-Check herunterladen',
    downloadHackTest: 'CheckFix Hack-Test herunterladen',
    sendEmail: 'Email senden',
    send: 'Senden',
    subject: 'Betreff',
    content: 'Inhalt',
    cancel: 'Abbrechen',
    customerList: 'Kundenliste',
    userList: 'Benutzerliste',
    action: 'Aktionen',
    checkType: "Check",
    checkShared: "Geteilt",
    id: 'ID',
    uploadFiles: 'Dateien hochladen',
    show: 'Anzeigen',
    share: "Teilen",
    save: 'Speichern',
    close: 'Schließen',
    upload: 'Hochladen',
    setPaid: 'Der Paymentstatus wird geändert',
    paymentMissing: 'Erwerben sie den Hack-Test',
    paymentInfo: `Den Hack-Test können sie herunterladen, nachdem sie entweder den einmaligen Hack-Test oder das ABO gekauft haben. Sobald die Bezahlung eingegangen ist erhalten sie ein E-Mail und können den Hack-Test herunterladen. <br><br> Bestellen Sie die gewünschte Variante sofort!`,
    paymentStatus: "Bezahlstatus ändern",
    paymentStatusInfo: "Ändern Sie den Zahlungsstatus des ausgewählten Kunden manuell. Es kann entweder eine einmalige Zahlung, ein ABO oder keine Zahlung sein.",
    hackTest: 'Hack-Test',
    hackTestABO: 'Hack-Test ABO',
    oneTimePayment: "Einmalig",
    aboPayment: "ABO",
    noPayment: "Keine Zahlung",
    welcomePageHackTest: 'Danke, dass sie sich für CheckFix und den Hack-Test entschieden haben.',
    welcomePageHackTestAbo: 'Danke, dass sie sich für CheckFix und das Hack-Test ABO entschieden haben.',
    welcomePageStatusCheck: 'Danke, dass sie sich für CheckFix und einen Status-Check entschieden haben.',
    emailSent: "Wir haben Ihnen ein E-Mail geschickt. Klicken Sie bitte auf den Link im Mail, um die Registrierung abzuschließen.",
    emailReceived: "Kein Mail gefunden? Prüfen Sie bitte Ihrem Spam-Ordner.",
    inviteOthers: "Kennen Sie jemanden, der cyber-sicher werden möchte? Teilen Sie Ihre Begeisterung für CheckFix, indem Sie deren E-Mail-Adresse eingeben und wir senden eine Einladung.",
    inviterEmptyError: "Bitte geben Sie eine gültige E-Mail Adresse ein.",
    forgotDashboardLinkText: "Geben Sie die E-Mail Adresse ein, mit der ihre Organisation/Firma registriert ist. Danach senden wir Ihnen per E-Mail den Zugang zu Ihrem Dashboard zu.",
    invite: "Einladen",
    emailPlaceholderInvite: "Geben Sie die Adresse ein die sie einladen möchten!",
    contact: "Bei Fragen oder Problemen kontaktieren Sie uns unter: ",
    support_email: "support@checkfix.io",
    registerPageStatusCheck: '2 Schritte bis zum Status-Check ihres Unternehmens',
    registerPageHackTest: '2 Schritte bis zum Hack-Test ihres Unternehmens',
    registerPageHackTestAbo: '2 Schritte bis zum Hack-Test Abo ihres Unternehmens',
    verificationText: "Sie haben ihre E-Mail Adresse erfolgreich bestätigt.\n\n Die Überprüfung startet automatisch. CheckFix informiert sie sobald die Überprüfung fertig ist.",
    addDomainsText: "Fügen Sie Domänen hinzu, die Sie zusätzlich überprüfen möchten. Sie können 2 weitere Domänen hinzufügen. Alle Domänen werden zusammen dargestellt. \n\n Geben sie nur den Domänennamen (z.B.: example.com) an.",
    runHackTest: "CheckFix ausführen",
    title: 'Überschrift',
    domainLimitReached: "Sie können maximal 2 Domänen hinzufügen.",
    addCustomer: "Kunde hinzufügen",
    addDomains: "Zusätzlich zu überprüfender Domänenname",
    editDomains: "Überprüfte Domänen bearbeiten",
    add: "Hinzufügen",
    perform: "Durchführen",
    performStatusCheck: "Status-Check durchführen",
    performStatusCheckMessage: "Wollen sie wirklich einen Status-Check für diesen Kunden durchführen",
    performHackTest: "Hack-Test durchführen",
    performHackTestMessage: "Wollen sie wirklich einen Hack-Test für diesen Kunden durchführen",
    performHackTestInfoMessage: "Wollen sie wirklich einen Hack-Test für diesen Kunden durchführen?\nJeder Hack-Test wird extra verrechnet, sie bekommen eine Rechnung per E-Mail.",
    addUser: "Benutzer hinzufügen",
    language: "Sprache",
    deleteCustomer: "Kunde löschen",
    deleteUser: "Benutzer löschen",
    checkSent: "Check gesendet?",
    checkCreated: "Check erstellt",
    customers: "Kunden",
    users: "Benutzer",
    name : "Name",
    uploadStatusCheck: "CheckFix Status-Check hochladen",
    uploadHackTest: "CheckFix Hack-Test hochladen",
    uploadPhoto: "Bild hochladen",
    selectFileType: "Typ auswählen",
    selectFile: "Datei auswählen",
    uploadFile: "Datei hochladen",
    uploadedFiles: "Hochgeladene Dateien",
    selectImageType: "Bildtyp auswählen",
    selectImage: "Bild auswählen",
    logs: "Kundenaktionen",
    date: "Datum",
    backToPortal: "Kundenübersicht",
    fileList: "Dateien",
    addCheck: "Check erstellen",
    validity: "Noch Gültig (Tage)",
    days: "Tag(e)",
    deleteCheckMessage: "Möchten Sie diesen Check wirklich löschen?",
    deleteMessageUser: "Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?",
    deleteMessageCustomer: "Sind Sie sicher, dass Sie diesen Kunden löschen möchten?",
    pageNotFoundTitle: "404 - Seite nicht gefunden",
    pageNotFoundDescription: "Entschuldigung, die Seite, die Sie suchen, existiert nicht.",
    pageNotFoundReturnLink: "Zurück zur Startseite",
    invalidUsernameOrPassword: "Benutzername oder API Key ungültig",
    unauthorized: "Sie sind nicht autorisiert für diesen Bereich"
}
